<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

// Components
import Button from '@/Components/Button/Button.vue';
import Footer from '@/Components/Footer/FooterLogin.vue';

// Layouts
import GuestLayout from '@/Layouts/GuestLayout.vue';

let route = inject('route');

const props = defineProps({
    email: String,
    token: String,
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <GuestLayout :title="$t('Reset password')">
        <div class="container flex flex-col items-center w-full relative -top-[200px]">
            <div class="w-full p-8 bg-white rounded-2xl max-w-[550px] text-blue">
                <h1 class="text-[32px] font-bold mb-2">{{ $t('Reset password') }}</h1>

                <div v-if="status" class="mb-4 text-sm font-medium text-green-500-600">
                    {{ status }}
                </div>

                <p class="mb-10 text-base">
                    {{ $t('Enter your e-mail and we send you a reset link') }}
                </p>

                <form @submit.prevent="submit">
                    <FormKit
                        type="text"
                        :label="$t('Email')"
                        v-model="form.email"
                        :errors="form.errors.email"
                        :value="form.email"
                        validation="required"
                    />

                    <FormKit
                        type="password"
                        :label="$t('Password')"
                        v-model="form.password"
                        :errors="form.errors.password"
                        :value="form.password"
                        validation="required"
                    />

                    <FormKit
                        type="password"
                        :label="$t('Confirm Password')"
                        v-model="form.password_confirmation"
                        :errors="form.errors.password_confirmation"
                        :value="form.password_confirmation"
                        validation="required"
                    />

                    <Button full-width :processing="form.processing">{{ $t('Reset Password') }}</Button>
                </form>
            </div>
            <Footer />
        </div>
    </GuestLayout>
</template>
